const translations = {
  days: {
    en: "DAYS",
    es: "DÍAS",
  },
  hours: {
    en: "HOURS",
    es: "HORAS",
  },
  minutes: {
    en: "MINUTES",
    es: "MINUTOS",
  },
  seconds: {
    en: "SECONDS",
    es: "SEGUNDOS",
  },
  last_places_available: {
    en: "Last places available",
    es: "Últimas plazas disponibles",
  },
  male: {
    en: "Male",
    es: "Masculino",
    fr: "Mâle",
    it: "Maschio",
    de: "Männlich",
    pt: "Macho",
    ch: "男性",
    ru: "мужской",
    my: "lelaki",
    hi: "पुरुष"
  },
  female: {
    en: "Female",
    es: "Femenino",
    fr: "Femelle",
    it: "Femmina",
    de: "Weiblich",
    pt: "Fêmea",
    ch: "女性",
    ru: "женский",
    my: "perempuan",
    hi: "महिला"
  },
  other_gender: {
    en: "Other",
    es: "Otro",
    fr: "Autre",
    it: "Altro",
    de: "Andere",
    pt: "Outro",
    ch: "其他",
    ru: "другой",
    my: "lain",
    hi: "अन्य"
  },
  noAvailabilityToday: {
    en: "No available today",
    es: "No disponible hoy",
    fr: "Pas disponible aujourd"
  },
  availableAt: {
    en: "Available in",
    es: "Disponible en",
    fr: "Disponible en",
  },
  sentMessage: {
    en: "Your message has been sent",
    es: "Tu mensaje ha sido enviado",
    fr: "Votre message a été envoyé",
  },
  startDate: {
    en: "Start date",
    es: "Fecha de inicio",
    fr: "Date de début",
  },
  endDate: {
    en: "End date",
    es: "Fecha de fin",
    fr: "Date de fin",
  },
  dateRange: {
    en: "Date range",
    es: "Rango de fechas",
    fr: "Plage de dates",
    it: "Intervallo di date",
    de: "Datumsbereich",
    pl: "Zakres dat",
    hi: "तिथि सीमा",
    ms: "Julat tarikh",
  },
  confirm: {
    en: "Confirm",
    es: "Confirmar",
    fr: "Confirmer",
    it: "Confermare",
    de: "Bestätigen",
    pl: "Potwierdzać",
    hi: "पुष्टि करें",
    ms: "Mengesahkan",
  },
  amount: {
    en: "Amount",
    es: "Cantidad",
    fr: "Montant",
    it: "Quantità",
    de: "Menge",
    pl: "Ilość",
    hi: "मात्रा",
    ms: "Jumlah",
  },
  enterAmount: {
    en: "Enter amount",
    es: "Introduce cantidad",
    fr: "Entrez le montant",
    it: "Inserisci importo",
    de: "Menge eingeben",
    pl: "Wprowadź ilość",
    hi: "राशि दर्ज करें",
    ms: "Masukkan jumlah"
  },
  totalPrice: {
    en: "Total",
    es: "Total",
    fr: "Total",
    it: "Totale",
    de: "Gesamt",
    pl: "Całkowity",
    hi: "कुल",
    ms: "Jumlah keseluruhan",
  },
}

export default translations;
