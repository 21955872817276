<script>
  import { createEventDispatcher, onMount, tick } from "svelte";
  import FormValidate from "./FormValidate.svelte";
  import Closer from "./base/Closer.svelte";
  import TelField from "./base/TelField.svelte";
  import Button from "./base/Button.svelte";
  import {
    toArray,
    getWrittenName,
    isIPhone,
    splitNameAndPrice,
    fetchLocation,
    checkIfSomeItemCloseConversation,
    isMobile,
  } from "../utils/utilities";
  import Spinner from "./base/Spinner.svelte";
  import { fileUpload } from "../utils/sendwhatsapp_rewrite_chabi";
  import { isClosedConversationStore } from "../store";
  import Toast from "./base/Toast.svelte";
  import { currentLanguage } from "../store";
  import translations from "../translations";
  import { getFormFromLocalStorage, saveFormToLocalStorage } from "../utils/localStorage";
  import flatpickr from "flatpickr";
  import { Spanish } from "flatpickr/dist/l10n/es.js";
  import { French } from "flatpickr/dist/l10n/fr.js";
  import { Italian } from "flatpickr/dist/l10n/it.js";
  import { German } from "flatpickr/dist/l10n/de.js";
  import { Polish } from "flatpickr/dist/l10n/pl.js";
  import { Hindi } from "flatpickr/dist/l10n/hi.js";
  import { Malay } from "flatpickr/dist/l10n/ms.js";
  import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
  import SearchableSelect from "./base/SearchableSelect.svelte";
  import { getFullDayCategories, getFullDayEvents } from "../utils/getdata";
  import { get } from "svelte/store";

  const dispatch = createEventDispatcher();

  // --- Form and state variables ---
  let errorList = [];
  let formValidateList = [];
  let showBooking = false;
  let isLoading = false;
  let cookieModalExist = eval("typeof modalPrivacityTermsCookies") === "function";
  let currentLang = "en";
  let filteredNumber;
  let inputElements = [];
  let disabledDates = [];
  let enabledCategories = [];
  let flatPickerInstances = [];

  export let formitems;
  export let data;
  export let noclose = false;
  export let person;
  export let isBigperson = false;

  $: fullScreenMobile = data.expandFullScreenMobile;
  $: notQualified = false;
  $: isSent = false;
  $: sendMessage = data.transSuccessMessage ?? translations.sentMessage[currentLang];
  // Observa cambios en item.useCheckAvailability o item.checkCategoriesByDate
  $: {
    formitems.items.forEach((item, i) => {
      if (item.type === "selectBookingFullDay" && inputElements[i]) {
        initializeFlatpickrFullDays(i, item);
      }
    });
  }
  $: totalPrice = calculateTotalPrice();

  let isClosedConversation = false;

  let filtered = formitems.items.map((item) => item.fieldToFilterFrom);
  filteredNumber = filtered.filter((item) => item).length;
  formitems.items.filter((item) => item.type !== "freetext").forEach((a) => (a.val = ""));

  onMount(() => {
    // Subscribe to changes in currentLanguage
    currentLanguage.subscribe((value) => {
      currentLang = value;
    });

    // Initialize flatpickr for date and time inputs
    let selectBookingAlreadyDisplayed = false;
    formitems.items.forEach((item, i) => {
      if (item.type === "selectBooking") {
        selectBookingAlreadyDisplayed ? (item.displayDateSelect = false) : (item.displayDateSelect = true);
        flatPickerInstances[i] = flatpickr(inputElements[i], {
          enableTime: true,
          dateFormat: "d/m/Y, H:i",
          minDate: "today",
          time_24hr: true,
          locale: getFlatpickrLocale(currentLang),
          disableMobile: true,
          plugins: [
            new confirmDatePlugin({
              confirmIcon: "",
              confirmText: translations.confirm[currentLang],
              showAlways: false,
            }),
          ],
          onChange: (selectedDates) => {
            let date = new Date(selectedDates[0]);
            let timeZonedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
            item.dateVal = timeZonedDate.toISOString().slice(0, 16);
          },
        });
        selectBookingAlreadyDisplayed = true;
      } else if (item.type === "selectBookingFullDay") {
        initializeFlatpickrFullDays(i, item);
        enabledCategories = toArray(item.data);
      } else if (item.type === "selectAndPrice" && item.canMultiply) {
        item.amount = 1;
      }
    });
  });

  if (person?.bookingConfigs?.length > 0 && isBigperson) {
    showBooking = true;
  }

  // Function to get the Flatpickr locale
  function getFlatpickrLocale(currentLang) {
    switch (currentLang) {
      case "fr":
        return French;
      case "it":
        return Italian;
      case "de":
        return German;
      case "pl":
        return Polish;
      case "hi":
        return Hindi;
      case "ms":
        return Malay;
      case "es":
        return Spanish;
      default:
        return undefined; // By default it uses English
    }
  }

  async function initializeFlatpickrFullDays(index, item) {
    await tick();
    if (!inputElements[index]) return;

    flatPickerInstances[index] = flatpickr(inputElements[index], {
      mode: "range",
      dateFormat: "d/m/Y",
      minDate: "today",
      locale: getFlatpickrLocale(currentLang),
      disableMobile: true,
      disable: [],
      plugins: [
        new confirmDatePlugin({
          confirmIcon: "",
          confirmText: translations.confirm[currentLang],
          showAlways: false,
        }),
      ],
      onChange: (selectedDates, dateStr, instance) => {
        if (selectedDates.length === 2) {
          let startDate = new Date(selectedDates[0]);
          let endDate = new Date(selectedDates[1]);
          let timeZonedStartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
          let timeZonedEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
          item.startDateVal = timeZonedStartDate.toISOString().slice(0, 10);
          item.endDateVal = timeZonedEndDate.toISOString().slice(0, 10);

          if (item.useCheckAvailability) getEnabledCategories(item.startDateVal, item.endDateVal);

          if (formitems.showTotal) {
            totalPrice = calculateTotalPrice();
          }
        }
      },
    });
  }

  const handleFileSelected = (e, i) => {
    formitems.items[i].val = e.target.files[0];
  };

  // function to check if the zip code is valid and fetch the location data
  const handlePostalCodeBlur = async (postalCode) => {
    const locationData = await fetchLocation(postalCode, data.country);

    if (locationData) {
      const { city, province } = locationData;

      // create or update the city field
      let cityField = formitems.items.find((item) => item.label === "City");
      if (!cityField) {
        cityField = { label: "City", value: city, type: "hidden", required: false };
        formitems.items.push(cityField);
      } else {
        cityField.value = city;
      }

      // create or update the province field
      let provinceField = formitems.items.find((item) => item.label === "Province");
      if (!provinceField) {
        provinceField = { label: "Province", value: province, type: "hidden", required: false };
        formitems.items.push(provinceField);
      } else {
        provinceField.value = province;
      }

      await tick(); // Prevents a Svelte warning about updating the DOM during rendering
    }
  };

  const sendtocallme = async (url = null) => {
    if (isLoading) {
      return;
    }
    if (showBooking) {
      dispatch("booking");
      return;
    }

    for (let i in formValidateList) {
      try {
        formValidateList[i].validate();
      } catch (ex) {
        //console.log(ex);
      }
    }

    if (errorList.length == 0) {
      for (let i in formitems.items) {
        if (formitems.items[i].type === "file" && formitems.items[i].val && !filtered[i]) {
          isLoading = true;
          const res = await fileUpload(formitems.items[i].val, person.id);
          formitems.items[i].val = res?.data?.id;
        }
        if (formitems.items[i].type === "hidden") {
          formitems.items[i].val = formitems.items[i].value ?? formitems.items[i].hval;
        }
        if (formitems.items[i].type === "selectBooking") {
          formitems.items[i].val = formitems.items[i].selectVal;
        }
        if (formitems.items[i].type === "selectBookingFullDay") {
          formitems.items[i].val = formitems.items[i].selectVal;
        }
      }
      isLoading = false;
      let scoreCnt = 0,
        scoreSum = 0,
        score;
      if (person.form.activateScoringFilter) {
        for (let item of formitems.items) {
          if (item.type === "select") {
            score = item.dataObject.find((obj) => obj.value === item.val)?.score;
            switch (score) {
              case "gold":
                scoreSum += 3;
                break;
              case "silver":
                scoreSum += 2;
                break;
              case "bronze":
                scoreSum += 1;
                break;
            }
            scoreCnt++;
          }
        }
      }

      // save form data to local storage for prefilling
      if (data.saveDataInWidget) {
        let formData = formitems.items.filter((item, i) => item.type !== "freetext" && !filtered[i]);
        saveFormToLocalStorage(data.id, formData);
      }

      notQualified =
        (scoreCnt > 0 && scoreSum / scoreCnt < 2) ||
        (person.online === false && data.offlineLeads === true) ||
        (data.desktopLeads === true && window.innerWidth > 1000);

      dispatch("callmex", {
        data: formitems.items.filter((item, i) => item.type !== "freetext" && !filtered[i]),
        url: url,
        notQualified: notQualified,
      });

      if (!checkIfSomeItemCloseConversation(formitems.items)) {
        sendMessage = data.transSuccessMessage ?? translations.sentMessage[currentLang];
      }

      isSent = true;

      setTimeout(() => {
        isSent = false;
      }, 3500);

      clearForm();
    }
  };

  const handleKeyPress = (event) => {
    if (event.code == "Enter") {
      event.preventDefault();
    }
  };

  const handleBlur = async (event, index) => {
    if (isClosedConversation) {
      return;
    }
    if (event.code == "Enter") {
      event.preventDefault();
    }
    formitems.items.forEach((item, i) => {
      checkFilter(i);
    });

    if (formitems.showTotal) {
      totalPrice = calculateTotalPrice();
    }

    if (formitems.items[index].type === "selectBookingFullDay" && formitems.items[index].useCheckAvailability) {
      if (!formitems.items[index].checkCategoriesByDate) {
        await getDisabledDates(index);
      }
    }

    // if field type is selectAndPrice, check min and max amount
    if (formitems.items[index].type === "selectAndPrice" && formitems.items[index].canMultiply) {
      checkMinAndMaxAmount(formitems.items[index]);
    }

    // Si el campo es de tipo "zipcode", llama a handlePostalCodeBlur
    if (formitems.items[index].type === "zipcode") {
      await handlePostalCodeBlur(formitems.items[index].val);
    }

    if (formitems.items[index].type === "select") {
      const option = formitems.items[index].dataObject.find((obj) => obj.value === formitems.items[index].val);
      if (option?.closeConversation) {
        sendMessage = option.closeConversationMessage;
      }
    }

    if (formitems.items[index].type === "email" && formitems.items[index].filterDomain) {
      // check if is gmail or hotmail
      let domain = formitems.items[index].val.split("@")[1];
      domain = domain?.split(".")[0];
      if (domain === "gmail" || domain === "hotmail") {
        sendMessage = formitems.items[index].closeConversationMessage;
      }
    }
    checkNewVisibleFields(formitems.items[index]);
  };

  let isDateInput = Array(person.form.items.length).fill(false);
  let isTimeInput = Array(person.form.items.length).fill(false);

  function onDateFocus(index) {
    isDateInput[index] = true;
  }

  function onDateBlur(index) {
    isDateInput[index] = false;
  }

  function onTimeFocus(index) {
    isTimeInput[index] = true;
  }

  function onTimeBlur(index) {
    isTimeInput[index] = false;
  }

  const checkFilter = (index) => {
    if (index < 0) {
      return false;
    }
    const item = formitems.items[index];
    if (!item.fieldToFilterFrom) {
      return false;
    }
    const values = item.valueToFilterFrom.split("|");

    const checkItem = formitems.items[item.fieldToFilterFrom - 1];
    let isMatch = true;
    if (filtered[item.fieldToFilterFrom - 1] !== true) {
      values.forEach((value) => {
        if (checkItem.type === "checkbox") {
          if (checkItem.val === (value === 1 || value === "1")) {
            isMatch = false;
          }
        } else if (checkItem.type === "selectBookingFullDay") {
          if (value?.trim() === checkItem.selectVal?.trim()) {
            isMatch = false;
          }
        } else if (value.trim() === checkItem.val.trim()) {
          isMatch = false;
        }
      });
    }

    filtered[index] = isMatch;
    return isMatch;
  };

  const clearForm = () => {
    formitems.items.forEach((item) => {
      item.val = "";
    });
  };

  const prefillForm = (formId) => {
    if (data.saveDataInWidget) {
      const savedData = getFormFromLocalStorage(formId);
      if (savedData) {
        formitems.items.forEach((item, i) => {
          const savedItem = savedData.find((saved) => saved.id === item.id);
          if (savedItem) {
            item.val = savedItem.val;
            if (item.type === "tel") {
              item.telNum = savedItem.telNum;
              item.telCode = savedItem.telCode;
            }
            if (item.type === "zipcode") {
              handlePostalCodeBlur(savedItem.val);
            }
          }
        });
      }
    }
  };

  prefillForm(data.id);

  async function selectOption(data, item, element) {
    await tick();

    if (item.type === "selectAndPrice" && item.canMultiply) {
      checkMinAndMaxAmount(item);
    }

    // check if has to close the conversation
    const option = item.dataObject.find((obj) => obj.value === item.val);
    if (option.closeConversation) {
      sendMessage = option.closeConversationMessage;
    }

    formitems.items.forEach((_, i) => {
      checkFilter(i);
    });

    checkNewVisibleFields(item);

    if (formitems.showTotal) {
      totalPrice = calculateTotalPrice();
    }
  }

  async function unselectOption(data, item, element) {
    await tick();

    if (item.type === "selectAndPrice" && item.canMultiply) {
      item.amount = 1;
      item.minAmount = undefined;
      item.maxAmount = undefined;
    }

    formitems.items.forEach((_, i) => {
      checkFilter(i);
    });

    checkNewVisibleFields(item);
  }

  function replaceURLsWithLinks(text) {
    // Regular expression to match URLs outside of <a> tags
    const urlRegex = /(?<!<a\s+[^>]*?href=["'])https?:\/\/[^\s<]+(?![^<]*<\/a>)/g;

    // Replace URLs outside of <a> tags with <a> tags
    return text.replace(urlRegex, (url) => {
      return `<br/><br/><b><a href="${url}" target="_blank">${url}</a></b><br/><br/>`;
    });
  }

  function scrollToNext(item) {
    tick().then(() => {
      let nextIndex = formitems.items.findIndex((i) => i.id === item.id) + 1;
      let nextElement = document.getElementById(formitems.items[nextIndex]?.id);
      if (nextElement) {
        nextElement.scrollIntoView({ behavior: "smooth" });
      }
    });
  }

  function checkNewVisibleFields(item) {
    if (filtered.filter((i) => i).length < filteredNumber) {
      // if there are new visible fields, scroll to next item to make sure the user sees them
      scrollToNext(item);
    }
    // update the number of visible fields
    filteredNumber = filtered.filter((item) => item).length;
  }

  async function getDisabledDates(index) {
    let category = formitems.items[index].dataObject.find((obj) => obj.value === formitems.items[index].selectVal);
    let datesToDisable = await getFullDayEvents(person.id, category?.id);
    if (!datesToDisable) {
      flatPickerInstances[index].set("disable", []);
      return;
    }
    datesToDisable = datesToDisable.map((event) => {
      let from = event.agreedStartTime.date.split(" ")[0];
      //reverse the date format
      from = from.split("-").reverse().join("-");
      let to = event.agreedStopTime.date.split(" ")[0];
      //reverse the date format
      to = to.split("-").reverse().join("-");
      return {
        from: from,
        to: to,
      };
    });
    disabledDates = datesToDisable;

    flatPickerInstances[index].set("disable", disabledDates);
  }

  async function getEnabledCategories(fromDate, toDate) {
    enabledCategories = await getFullDayCategories(person.id, fromDate, toDate);
    enabledCategories = enabledCategories.map((category) => category.code);
  }

  function checkMinAndMaxAmount(item) {
    let selectedOption = item.dataObject.find((option) => option.value === item.val);
    if (selectedOption) {
      item.minAmount = selectedOption.canMultiplyMin;
      item.maxAmount = selectedOption.canMultiplyMax;
    }
  }

  function calculateTotalPrice() {
    let total = 0;

    for (const item of formitems.items) {
      // For "selectAndPrice"
      if (item.type === "selectAndPrice") {
        if (item.val) {
          const option = item.dataObject && item.dataObject.find((opt) => opt.value === item.val);
          if (option) {
            const numericPrice = parseFloat(option.price) || 0;
            // If canmultiply, use item.amount; otherwise, use the price as is
            const amount = item.canMultiply ? parseFloat(item.amount) || 1 : 1;
            total += numericPrice * amount;
          }
        }
      }
      // For "selectBooking"
      else if (item.type === "selectBooking") {
        if (item.selectVal) {
          const option = item.dataObject && item.dataObject.find((opt) => opt.value === item.selectVal);
          if (option) {
            const price = option.price;
            total += parseFloat(price) || 0;
          }
        }
      }
      // For "selectBookingFullDay"
      else if (item.type === "selectBookingFullDay") {
        if (item.selectVal && item.startDateVal && item.endDateVal) {
          const option = item.dataObject && item.dataObject.find((opt) => opt.value === item.selectVal);
          if (option) {
            const numericPrice = parseFloat(option.price) || 0;
            // Calculate the number of days between the start and end dates
            const start = new Date(item.startDateVal);
            const end = new Date(item.endDateVal);
            const diffTime = Math.abs(end - start);
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)) + 1;
            total += numericPrice * diffDays;
          }
        }
      }
      // For "checkbox"
      else if (item.type === "checkbox") {
        if (item.val && item.price) {
          total += parseFloat(item.price) || 0;
        }
      }
      // For "freetext"
      else if (item.type === "freetext") {
        if (item.showPrice && item.price) {
          total += parseFloat(item.price) || 0;
        }
      }
    }
    return total;
  }
</script>

<div class:fullScreenMobile class="chatwith-form-wrapper">
  <div class="chatwith-form">
    <div style="padding-top: 20px">
      {#if noclose !== true}
        <Closer on:close={() => dispatch("close")} />
      {/if}
      {#if !showBooking}
        {#if formitems.info != ""}
          <div class="textito">{formitems.info}</div>
        {/if}
        {#each formitems.items as item, i}
          <div class:hidden={filtered[i]}>
            {#if item.type == "text"}
              <div class="form-element">
                <input
                  id={item.id}
                  bind:value={item.val}
                  type="text"
                  class={item.isError ? "error-border" : ""}
                  placeholder={`${item.label}${item.required ? "*" : ""}`}
                  on:keypress={handleKeyPress}
                  on:keyup={(evt) => handleBlur(evt, i)}
                />
              </div>
            {:else if item.type == "file"}
              <div class="form-element">
                <p class="file-label">{item.label}</p>
                <input
                  id={item.id}
                  bind:value={item.val}
                  type="file"
                  class={item.isError ? "error-border" : ""}
                  accept=".pdf, .docx, .xlsx, .jpg, .jpeg, .png, .zip"
                  on:change={(e) => handleFileSelected(e, i)}
                />
              </div>
            {:else if item.type == "number"}
              <div class="form-element">
                <input
                  id={item.id}
                  bind:value={item.val}
                  type="number"
                  class={item.isError ? "error-border" : ""}
                  placeholder={`${item.label}${item.required ? " *" : ""}`}
                  on:keypress={handleKeyPress}
                  on:keyup={(evt) => handleBlur(evt, i)}
                />
              </div>
            {:else if item.type == "url"}
              <div class="form-element">
                <input
                  id={item.id}
                  bind:value={item.val}
                  type="url"
                  class={item.isError ? "error-border" : ""}
                  placeholder={`${item.label}${item.required ? " *" : ""}`}
                  on:keypress={handleKeyPress}
                  on:keyup={(evt) => handleBlur(evt, i)}
                />
              </div>
            {:else if item.type == "date"}
              <div class="form-element">
                {#if isMobile()}
                  {#if isDateInput[i]}
                    <input
                      id={item.id}
                      class="form-element-input {item.isError ? 'error-border' : ''}"
                      bind:value={item.val}
                      type="date"
                      placeholder={item.val ? "" : item.label}
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                  {:else}
                    <input
                      id={item.id}
                      class="form-element-input {item.isError ? 'error-border' : ''}"
                      bind:value={item.val}
                      type="date"
                      placeholder={item.val ? "" : item.label}
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                  {/if}
                {:else}
                  <div class="chatwith-input-label">{item.label}</div>
                  <input
                    id={item.id}
                    class="form-element-input {item.isError ? 'error-border' : ''}"
                    bind:value={item.val}
                    type="date"
                    on:change={(evt) => handleBlur(evt, i)}
                  />
                {/if}
              </div>
            {:else if item.type == "time"}
              <div class="form-element">
                {#if isMobile()}
                  {#if isTimeInput[i]}
                    <input
                      id={item.id}
                      class="form-element-input {item.isError ? 'error-border' : ''}"
                      bind:value={item.val}
                      type="time"
                      placeholder={item.val ? "" : item.label}
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                  {:else}
                    <input
                      id={item.id}
                      class="form-element-input {item.isError ? 'error-border' : ''}"
                      bind:value={item.val}
                      type="time"
                      placeholder={item.val ? "" : item.label}
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                  {/if}
                {:else}
                  <div class="chatwith-input-label">{item.label}</div>
                  <input
                    id={item.id}
                    class="form-element-input {item.isError ? 'error-border' : ''}"
                    bind:value={item.val}
                    type="time"
                    on:change={(evt) => handleBlur(evt, i)}
                  />
                {/if}
              </div>
            {:else if item.type == "select"}
              {#if !item.showAsButton}
                <div class="form-element">
                  {#if item.usePredictiveText}
                    <SearchableSelect
                      id={item.id}
                      {item}
                      bind:selected={item.val}
                      options={toArray(item.data)}
                      placeholder={item.label}
                      showLabel={false}
                      label={item.label}
                      required={item.required}
                      on:change={(evt) => handleBlur(evt, i)}
                      isError={item.isError}
                    />
                  {:else}
                    <div class="chatwith-select">
                      <!-- svelte-ignore a11y-no-onchange -->
                      <select id={item.id} bind:value={item.val} class={item.isError ? "error-border" : ""} on:change={(evt) => handleBlur(evt, i)}>
                        <option value="">--{item.label}--</option>
                        {#each toArray(item.data) as data}
                          <option value={data}>{getWrittenName(data, item?.dataObject)}</option>
                        {/each}
                      </select>
                    </div>
                  {/if}
                </div>
              {:else}
                <div class="form-element">
                  <div class="chatwith-button-label">
                    {item.label}
                  </div>
                  <div class="chatwith-button-options">
                    {#each toArray(item.data) as data}
                      <button
                        type="button"
                        class="chatwith-option-button {item.val == data ? 'selected' : ''}"
                        on:click={(e) => {
                          selectOption(data, item, e.target);
                          item.val = data;
                        }}
                      >
                        {getWrittenName(data, item?.dataObject)}
                      </button>
                    {/each}
                  </div>
                  <input id={item.id} bind:value={item.val} type="hidden" />
                </div>
              {/if}
            {:else if item.type == "selectAndPrice"}
              <div class="form-element">
                {#if !item.showAsButton}
                  {#if item.usePredictiveText}
                    <SearchableSelect
                      id={item.id}
                      {item}
                      bind:selected={item.val}
                      options={toArray(item.data)}
                      placeholder={item.label}
                      showLabel={false}
                      label={item.label}
                      required={item.required}
                      on:change={(evt) => handleBlur(evt, i)}
                      isError={item.isError}
                      currency={data?.currency}
                    />
                  {:else}
                    <div class="chatwith-select">
                      <!-- svelte-ignore a11y-no-onchange -->
                      <select id={item.id} bind:value={item.val} class={item.isError ? "error-border" : ""} on:change={(evt) => handleBlur(evt, i)}>
                        <option value="">--{item.label}--</option>
                        {#each toArray(item.data) as itemData}
                          <option value={itemData}>{getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)}</option>
                        {/each}
                      </select>
                    </div>
                  {/if}
                {:else}
                  <div class="chatwith-button-label">
                    {item.label}
                  </div>
                  <div class="chatwith-button-options">
                    {#each toArray(item.data) as itemData}
                      <button
                        type="button"
                        class="chatwith-option-button {item.val == itemData ? 'selected' : ''}"
                        on:click={(e) => {
                          if (item.val === itemData) {
                            item.val = "";
                            unselectOption(itemData, item, e.target);
                          } else {
                            item.val = itemData;
                            selectOption(itemData, item, e.target);
                          }
                        }}
                      >
                        <div class="chatwith-option-button-text">
                          <span class="chatwith-option-button-name"
                            >{splitNameAndPrice(getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)).name}</span
                          >
                          {#if item?.showPrices}
                            <small class="chatwith-option-button-price"
                              >({splitNameAndPrice(getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)).price})</small
                            >
                          {/if}
                        </div>
                      </button>
                    {/each}
                  </div>
                  <input id={item.id} bind:value={item.val} type="hidden" />
                {/if}
                {#if item.canMultiply}
                  <div class="chatwith-multiply-input" style="margin-top: 10px">
                    <div class="chatwith-input-label">{translations.amount[currentLang]}:</div>
                    <input
                      id={`amount-${item.id}`}
                      type="number"
                      min={item.minAmount ?? 0}
                      max={item.maxAmount}
                      bind:value={item.amount}
                      placeholder={translations.enterAmount[currentLang]}
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                  </div>
                {/if}
              </div>
            {:else if item.type == "checkbox"}
              <div class="form-element">
                <div>
                  <label>
                    <input id={item.id} value={false} bind:checked={item.val} on:change={(evt) => handleBlur(evt, i)} type="checkbox" />
                    <p style="font-size: 12px">
                      {item.label}
                      {item.showPrice && item.price && item.price > 0 ? `(${item.price} ${data?.currency})` : ""}
                    </p>
                  </label>
                </div>
              </div>
            {:else if item.type == "hidden"}
              <div class="form-element">
                <span style="display: none">{item.label}</span>
                <input id={item.id} bind:value={item.value} type="hidden" />
              </div>
            {:else if item.type == "tel"}
              <div class="form-element">
                <TelField
                  {data}
                  bind:value={item.val}
                  bind:telNum={item.telNum}
                  bind:telCode={item.telCode}
                  placeholder={`${item.label}${item.required ? " *" : ""}`}
                  isError={item.isError}
                  on:message={(evt) => handleBlur(evt, i)}
                />
                <input id={item.id} bind:value={item.val} type="hidden" />
              </div>
            {:else if item.type == "zipcode"}
              <div class="form-element">
                <input
                  id={item.id}
                  bind:value={item.val}
                  type="text"
                  class={item.isError ? "error-border" : ""}
                  placeholder={`${item.label}${item.required ? "*" : ""}`}
                  on:keypress={handleKeyPress}
                  on:keyup={(evt) => handleBlur(evt, i)}
                />
              </div>
            {:else if item.type == "gender"}
              <div class="form-element">
                <div class="chatwith-radio-buttons-label">
                  {item.label}
                </div>
                <div class="chatwith-radio-buttons-container">
                  <label>
                    <input
                      class="chatwith-radio-button"
                      type="radio"
                      name="gender-{i}"
                      bind:group={item.val}
                      value="M"
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                    {translations.male[currentLang]}
                  </label>
                  <label>
                    <input
                      class="chatwith-radio-button"
                      type="radio"
                      name="gender-{i}"
                      bind:group={item.val}
                      value="F"
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                    {translations.female[currentLang]}
                  </label>
                  <label>
                    <input
                      class="chatwith-radio-button"
                      type="radio"
                      name="gender-{i}"
                      bind:group={item.val}
                      value="Other"
                      on:change={(evt) => handleBlur(evt, i)}
                    />
                    {translations.other_gender[currentLang]}
                  </label>
                </div>
                <input id={item.id} bind:value={item.val} type="hidden" />
              </div>
            {:else if item.type === "selectBooking"}
              <div class="form-element">
                <div class="chatwith-freebooking">
                  <!-- Select and Price -->
                  <div class="chatwith-select">
                    <!-- svelte-ignore a11y-no-onchange -->
                    <select
                      id={item.id}
                      bind:value={item.selectVal}
                      class={item.isError ? "error-border" : ""}
                      on:change={(evt) => handleBlur(evt, i)}
                    >
                      <option value="">--{item.label}--</option>
                      {#each toArray(item.data) as itemData}
                        <option value={itemData}>{getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)}</option>
                      {/each}
                    </select>
                  </div>

                  <!-- Date input -->
                  <div class="form-element" style={item.displayDateSelect ? "" : "display: none;"}>
                    <input
                      bind:this={inputElements[i]}
                      placeholder={translations.startDate[currentLang]}
                      class="form-element-input {item.isError ? 'error-border' : ''}"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            {:else if item.type === "selectBookingFullDay"}
              <div class="form-element">
                <div class="chatwith-freebooking">
                  <!-- Date range input before if checkCategoriesByDate -->
                  {#if item.useCheckAvailability && item.checkCategoriesByDate}
                    <div class="form-element">
                      <div class="chatwith-input-label">{translations.dateRange[currentLang] || "Date Range"}</div>
                      <input
                        bind:this={inputElements[i]}
                        placeholder={translations.dateRange[currentLang] || "Date Range"}
                        class="form-element-input {item.isError ? 'error-border' : ''}"
                        type="text"
                      />
                    </div>
                  {/if}

                  <!-- Select option -->
                  {#if (item.startDateVal && item.endDateVal) || !item.useCheckAvailability || !item.checkCategoriesByDate}
                    <div class="chatwith-select form-element">
                      <!-- svelte-ignore a11y-no-onchange -->
                      <select
                        id={item.id}
                        bind:value={item.selectVal}
                        class={item.isError ? "error-border" : ""}
                        on:change={(evt) => handleBlur(evt, i)}
                      >
                        <option value="">--{item.label}--</option>
                        {#each enabledCategories as itemData}
                          <option value={itemData}>{getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)}</option>
                        {/each}
                      </select>
                    </div>
                  {/if}

                  <!-- Date range input after if !checkCategoriesByDate -->
                  {#if !item.checkCategoriesByDate || !item.useCheckAvailability}
                    <div class="form-element">
                      <div class="chatwith-input-label">{translations.dateRange[currentLang]}</div>
                      <input
                        bind:this={inputElements[i]}
                        placeholder={translations.dateRange[currentLang]}
                        class="form-element-input {item.isError ? 'error-border' : ''}"
                        type="text"
                      />
                    </div>
                  {/if}
                </div>
              </div>
            {:else if item.type == "email"}
              <div class="form-element">
                <input
                  id={item.id}
                  bind:value={item.val}
                  type="email"
                  class={item.isError ? "error-border" : ""}
                  placeholder={`${item.label}${item.required ? " *" : ""}`}
                  on:keypress={handleKeyPress}
                  on:keyup={(evt) => handleBlur(evt, i)}
                />
              </div>
            {:else if item.type == "freetext" && item.hval}
              <div style="font-size: 13px !important;" class="form-element">
                <span class="cw-freetext-inline">{@html replaceURLsWithLinks(item.hval)}</span>
                {item.showPrice && item.price && item.price > 0 ? `(${item.price} ${data?.currency})` : ""}
              </div>
            {/if}
            {#if !filtered[i]}
              <FormValidate
                bind:value={errorList}
                bind:this={formValidateList[i]}
                on:error={(val) => {
                  item.isError = val.detail;
                }}
                {item}
                {data}
                index={i}
              />
            {/if}
            {#if item.type == "checkbox" && item.legalUrl}
              <div class="checkbox-link">
                {#if isBigperson && item.legalCanUseLocalModal && cookieModalExist}
                  {@html `<a style="font-size: 12px" target="_blank" onClick="return modalPrivacityTermsCookies('privacityPopup');">${
                    item.legalUrlText ?? "Click here to see the Privacy Policy"
                  }</a
                >`}
                {:else}
                  <a style="font-size: 12px" target="_blank" href={item.legalUrl}>{item.legalUrlText ?? "Click here to see the Privacy Policy"}</a>
                {/if}
              </div>
            {/if}
          </div>
        {/each}
      {/if}
    </div>
  </div>
</div>
<div class="chatwith-footer">
  {#if formitems.showTotal}
    <div class="cw-total-price">
      <span>{translations.totalPrice[currentLang]}: <b>{totalPrice.toFixed(2)} {data.currency}</b></span>
    </div>
  {/if}
  {#if formitems.buyLinks && formitems.buyLinks.length > 0 && !showBooking}
    {#each formitems.buyLinks as item, i}
      <Button
        id={"chatwith-buy-button" + i}
        on:click={() => sendtocallme(item.link)}
        buttonText={item.buttontext}
        showIcon={true}
        iconUrl={data.whatsappIconUrl}
        spanClassName={item.buttontext.length >= 25 ? "chatwith-button-long-span" : ""}
      />
    {/each}
  {:else}
    {#if isLoading}
      <Spinner />
    {/if}
    <Button
      on:click={() => sendtocallme(null)}
      buttonText={showBooking ? formitems.buttontext ?? "Book now" : formitems.buttontext}
      showIcon={true}
      iconUrl={data.whatsappIconUrl}
      spanClassName={formitems.buttontext.length >= 25 ? "chatwith-button-long-span" : ""}
      className={isSent ? "chatwith-button-disabled" : ""}
    >
      {#if isSent}
        <Toast message={sendMessage} visible={isSent} />
      {/if}
    </Button>
  {/if}
</div>
